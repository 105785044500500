import Vue from 'vue'
import pouchCore from 'pouchdb-core'
import adapterIdb from 'pouchdb-adapter-idb'

const PouchDB = pouchCore.plugin(adapterIdb)

if (!window.umami) window.umami = txt => console.log(txt)
class Pouch {
  constructor() {
    this.db = new PouchDB(`polskablio`, { auto_compaction: true })
    this.nuke = async () => {
      await this.db.destroy()
      this.db = await new PouchDB(`polskablio`, { auto_compaction: true })
      return this.db
    }
  }

  install($Vue) {
    $Vue.prototype.$bucket = this
  }
}

Vue.use(new Pouch())
